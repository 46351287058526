<template>
    <div>
        <div class="custom-steps">
            <div class="left-steps">
                <el-steps :align-center="true">
                    <el-step
                            v-for="(d, i) in topSteps"
                            :key="i"
                    >
                        <template slot="icon">
                            <div style="font-size: 36px">
                                <div v-if="d.active" class="activated">
                                    <i class="el-icon-success"></i>
                                </div>
                                <div v-else >
                                    <i class="el-icon-error"></i>
                                </div>
                            </div>

                        </template>
                        <template slot="title">
                            <div :class="{'activated' : d.active}" style="padding: 5px 0" class="pointer" @click="toTrace(d)">
                                {{d.name}}
                            </div>
                        </template>
                        <template slot="description">
                            <div :class="{'activated' : d.active}">
                                {{d.active ? '已完成' : '未完成'}}
                            </div>
                        </template>
                    </el-step>
                </el-steps>
                <div class="left-steps-bottom">
                    <el-steps v-for="(item,index) in bottomSteps" :key="index" align-center>
                        <el-step
                                v-for="(d, i) in item"
                                :key="i"
                        >
                            <template slot="icon">
                                <div style="font-size: 36px">
                                    <div v-if="d.active" class="activated">
                                        <i class="el-icon-success"></i>
                                    </div>
                                    <div v-else >
                                        <i class="el-icon-error"></i>
                                    </div>
                                </div>

                            </template>
                            <template slot="title">
                                <div :class="{'activated' : d.active}" style="padding: 5px 0">
                                    {{d.name}}
                                </div>

                            </template>
                            <template slot="description">
                                <div :class="{'activated' : d.active}">
                                    {{d.active ? '已完成' : '未完成'}}
                                </div>
                            </template>
                        </el-step>
                    </el-steps>

                </div>
            </div>

            <div v-for="(item,index) in midSteps" :key="index" :style="{marginTop: 26 + index * 120 + 'px'}" :class="{'right-steps' : index === 0,'pa-right' : (index % 2 === 0 && index != 0),'pa' : index % 2 !== 0}" v-show="midSteps.length > 0">
                <div :class="{'circle': index % 2 === 0,'circle-left' : index % 2 !== 0}" >
                    <div class="icon"  style="font-size: 36px" :class="{'activated' : item.active}">
                        <i v-if="item.active" class="el-icon-success"></i>
                        <i v-else class="el-icon-error"></i>
                        <span class="tips">
              <div class="f16" :class="{'activated' : item.active}">{{ item.name }}</div>
              <div :class="{'activated' : item.active}">{{ item.active ? '已完成' : '未完成' }}</div>
            </span>
                    </div>
<!--                    <div class="line" v-show="i != 0">-->

<!--                    </div>-->
                </div>

            </div>
        </div>
            <div class="t-dialog" id="fade-box" v-show="showInfo">
                <div class="p50 record-bg">
                    <div v-for="(item,index) in traceList" :key="index" class="df pr">
                        <div class="flex-8 tc col-fff f20 fw-b">
                            <h1>{{item.name}}</h1>
                        </div>
                        <div v-if="item.type == 2" class="flex-16 tl col-fff">
                            <h1>{{item.content}}</h1>
                        </div>
                        <div v-else-if="item.type == 1 " class="flex-16 tl ">
                            <el-image
                                    v-for="(src,srcIndex) in JSON.parse(item.content)"
                                    :key="srcIndex"
                                    style="width: 200px; height: 200px"
                                    :src="src.url"
                                    fit="cover"
                                    :preview-src-list="[src.url]"
                                    class="mr20 t-border mb10"
                            ></el-image>
                        </div>
                        <div v-else-if=" item.type ==6 " class="flex-16 tl ">
                            <el-image
                                    v-for="(src,srcIndex) in JSON.parse(item.content)"
                                    :key="srcIndex"
                                    style="width: 200px; height: 200px"
                                    :src="src.imgUrl"
                                    fit="cover"
                                    :preview-src-list="[src.imgUrl]"
                                    class="mr20 t-border mb10"
                            ></el-image>
                        </div>
                        <div v-else-if="(item.type == 4 || item.type == 3) && showInfo" class="flex-16 tl">
                            <div v-for="(vidoe,vidoeIndex) in JSON.parse(item.content)" :key="vidoeIndex">
                                <video Controls :poster="vidoe + '?x-oss-process=video/snapshot,t_1,m_fast'" class="video-js t-border mb10" style="height: 260px;width: 100%;margin-bottom: 5px">
                                    <source :src="vidoe" type="video/mp4"/>
                                </video>
                            </div>
                        </div>
                    </div>
                    <div class="t-close" @click="closeTrace">
                        ×
                    </div>
                    <div class="t-title">
                        工序记录
                    </div>
                </div>

            </div>

    </div>
</template>

<script>
    import api from "../util/extra-api";
    export default {
        name: "CustomStep",
        props: {
            stepsData: {
                type: Array,
                default() {
                    return [];
                }
            },
            companyId: {
                type: Number,
                default() {
                    return 0;
                }
            },
            haccpType: {
                type: Number,
                default() {
                    return 0;
                }
            }
        },
        data() {
            return {
                topSteps: [],
                midSteps: [],
                bottomSteps: [[],[],[],[],[],[]],
                midFlag: false,
                midNum: 0,
                showInfo: false,
                traceList:[]
            };
        },
        watch: {
        },
        mounted() {
            this.initStepsData();
        },
        methods: {
            toTrace(d) {
                //console.log(d)
                let data
                if(this.haccpType === 1){
                    data = {
                        cateringHaccpId: d.recordId,
                        companyId: this.companyId,
                        haccpTemplateId: d.id,
                        tag: 0
                    }
                }else{
                    data = {
                        productionRecordId: d.recordId,
                        companyId: this.companyId,
                        haccpTemplateId: d.id,
                        tag: 0
                    }
                }

                api.get('/v1/pc/haccpScreen/submitData',data).then(res =>{
                    if(res.code === 200){
                        this.traceList = res.data
                        this.showInfo = true
                    }
                })
            },
            closeTrace(){
                this.showInfo = false
            },
            initStepsData() {
                const stepsData = this.stepsData;
                if (stepsData && stepsData.length > 0 && stepsData.length <= 11) {
                    this.midNum = 11
                    this.topSteps = stepsData;
                }else if(stepsData && stepsData.length > 11){
                    let n = 10;
                    this.midNum = n;
                    this.topSteps = stepsData.slice(0, n);
                    //this.midSteps = stepsData[n]
                    //this.bottomSteps = stepsData.slice(n + 1).reverse();
                    stepsData.forEach((item,index) =>{
                        if(index % 10 ===0 && index != 0){
                            this.midSteps.push(stepsData[index])
                        }
                        else if(index > 11){
                           let rows = Math.floor((index / (n)))-1
                            if(rows % 2 === 0)
                                this.bottomSteps[rows].unshift(stepsData[index])
                            else
                                this.bottomSteps[rows].push(stepsData[index])
                        }
                    })
                }
            }
        }
    };
</script>

<style lang="scss">
    .el-step.is-center .el-step__line {
        left: 0 !important;
        right: -100% !important;
    }.el-step.is-center:nth-of-type(1) .el-step__line:nth-of-type(1) {
        left: 50% !important;
        right: -100% !important;
    }
    .line{
        position: absolute;
        width: 500%;
        height: 5px;
        bottom: -5px;
        right: 40px;
        background-color: #c0c4cc;
    }
    .activated{
        color: #1d953f!important;
    }
    .f16{
        font-size: 16px;
    }
    .pa{
        position: absolute;
        left: 60px;
    }.pa-right{
        position: absolute;
        right: 60px;
    }
    .circle {
        width: 160px;
        height: 125px;
        //margin-top: 25px;
        border: 5px solid #c0c4cc;
        border-radius: 0 30% 30% 0/50%;
        border-left: none;
        position: relative;
        .icon {
            position: absolute;
            right: 0;
            top: 50%;
            width: 36px;
            height: 36px;
            transform: translate(55%, -50%);
            background-color: #fff;
            border-radius: 50%;
            color: #c0c4cc;
            text-align: center;
            font-size: 12px;
            border: 8px solid #ffffff;
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            .tips {
                display: flex;
                position: absolute;
                right: 30px;
                font-size: 12px;
                color: #c0c4cc;
                width: 80px;
                padding-right: 8px;
                flex-direction: column;
                text-align: center;
                > span:first-child {
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
    }
    .circle-left {
        width: 160px;
        height: 126px;
        //margin-top: 25px;
        border: 5px solid #c0c4cc;
        border-radius:  30% 0 0 30%/50%;
        border-right: none;
        position: relative;
        .icon {
            position: absolute;
            left: -40px;
            top: 50%;
            width: 36px;
            height: 36px;
            transform: translate(55%, -50%);
            background-color: #fff;
            border-radius: 50%;
            color: #c0c4cc;
            text-align: center;
            font-size: 12px;
            border: 8px solid #ffffff;
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            .tips {
                display: flex;
                position: absolute;
                left: 30px;
                font-size: 12px;
                color: #c0c4cc;
                width: 80px;
                padding-right: 8px;
                flex-direction: column;
                text-align: center;
                > span:first-child {
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
    }
</style>

<style scoped lang="scss">
    .custom-steps {
        padding: 20px;
        display: flex;
        .left-steps {
            width: calc(100% - 150px);
            /*float: left;*/
        }
        .left-steps-bottom{
            width: calc(100% - 150);
            margin-left: 150px;
        }
        .right-steps {
            /*float: right;*/
            margin-left: -50px;
            height: 130px;
        }
        /deep/.el-step__icon {
            /*background: #f6f6f6;*/
        }
        /deep/.el-step__icon-inner {
            font-size: 36px;
        }
        /deep/.el-step__line {
            background: transparent;
            border-top: 5px solid;
            .el-step__line-inner {
                border-width: 0 !important;
            }
        }
        /deep/.el-step {
            position: relative;
            padding: 15px 0;
            .el-step__head.is-process {
                color: #c0c4cc;
                border-color: #c0c4cc;
            }
            .el-step__title.is-process {
                color: #c0c4cc;
                font-weight: unset;
            }
        }
    }
    .record-bg{
        background-image: url("../assets/image/cool-background.png");
        overflow: hidden;
        border-radius: 20px;
        background-size: cover;
        z-index: 100;
        /*background-repeat: no-repeat;*/
    }
    .p50{
        padding:50px 20px!important;
        &>div{
            padding: 10px 0;
            border-top: 1px solid #7bbfea;
            display: flex;
            align-items: center;
        }
        &:last-child{
            padding:  0;
            border-bottom: 1px solid #7bbfea;
        }
    }
    .pointer{
        cursor: pointer;
    }
    /*#fade-box {*/
    /*    animation: input-entry 3s ease-in;*/
    /*}*/

    $stark-light-blue: #00fffc;
    $stark-med-blue: #00a4a2;
    #fade-box {
        animation: form-entry 2s ease-in-out;
        /*background: #111;*/
        /*background: linear-gradient(#004746, #111);*/
        border: 1px solid $stark-med-blue;
        box-shadow: 0 0 15px #00fffd;
        border-radius: 20px;
        /*display: inline-block;*/
        /*height: 220px;*/
        /*margin: 200px auto 0;*/
        /*position: relative;*/
        z-index: 4;
        /*width: 500px;*/
        transition: 1s all ;

        &:hover {
            border: 1px solid #00fffd;
            box-shadow: 0 0 25px #00fffd;
            transition: 1s all ;
        }
    }
    @keyframes form-entry {
        0% {
            max-height: 80vh;
            width: 0;
            opacity: 0;
            padding: 0;
        }
        20% {
            max-height: 80vh;
            border: 4px solid $stark-med-blue;
            width: 0;
            opacity: 0;
            padding: 0;
        }


        40% {
            width: 0;
            max-height: 80vh;
            border: 4px solid $stark-med-blue;
            opacity: 1 ;
            padding: 0;
        }

        100% {
            max-height: 80vh;
            width: 800px;

        }
    }
    @keyframes input-entry {
        0% {
            opacity: 0;
        }
        90% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
</style>

